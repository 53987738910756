import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuelidate from "vuelidate";
import BootstrapVue from "bootstrap-vue";

//sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Cookies
import Cookies from 'js-cookie';

// vue-gtag 1.16.1 supports ga4 in vue2
import VueGtag from 'vue-gtag';

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(Cookies);

Vue.use(VueGtag, {
  config : {
    id : process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID
  }
});


Vue.mixin({
  methods: {
    rndStr(len) {
      let text = "";
      let chars = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      return text
    },
    errorMessages(error) {
      if (!error.response) {
        this.error = {
          message: 'Connection error. Refresh page and try again',
          type: 'general'
        };
      } else if (error.response.status === 401) {
        this.error = {
          message: error.response.data.message,
          type: error.response.data.type,
          borderPassword: true
        };
        this.$store.dispatch('logout');
      } else if (error.response.status === 400) {
        this.error = {
          message: error.response.data.message,
          type: 'password',
          borderPassword: true
        };
        // this.$store.dispatch('logout');
      } else if (error.response.status === 409) {
        this.error = {
          message: error.response.data.message,
          type: 'email',
          borderPassword: true
        }
      } else if (error.response.status === 406) {
        //used for Modal validation code
        this.error = {
          message: error.response.data.message,
          type: 'text',
          borderPassword: true
        }
      }
    },
    fetchAPI() {
      this.$store.dispatch('fetchAPI').then(res => {
          this.userData = res.data;
          if (res.data.accountStatus) {
            this.notifyExpiry = false
          } else {
            this.notifyExpiry = true
          }
      }).catch(error => {
        if(error.response.status === 400) {
          this.$store.dispatch('logout');
        } else {
          this.errorMessages(error);
        }
      })
    },
  }
})

Vue.prototype.$states = [
  {   "text": "Please select a state", 
      "value": null
  },
  {
      "text": "ANDAMAN AND NICOBAR",
      "value": "ANDAMAN AND NICOBAR"
  },
  {
      "text": "ANDHRA PRADESH",
      "value": "ANDHRA PRADESH"
  },
  {
      "text": "ARUNACHAL PRADESH",
      "value": "ARUNACHAL PRADESH"
  },
  {
      "text": "ASSAM",
      "value": "ASSAM"
  },
  {
      "text": "BIHAR",
      "value": "BIHAR"
  },
  {
      "text": "CHANDIGARH",
      "value": "CHANDIGARH"
  },
  {
      "text": "CHHATTISGARH",
      "value": "CHHATTISGARH"
  },
  {
      "text": "DADRA AND NAGAR HAVELI",
      "value": "DADRA AND NAGAR HAVELI"
  },
  {
      "text": "DAMAN AND DIU",
      "value": "DAMAN AND DIU"
  },
  {
      "text": "DELHI",
      "value": "DELHI"
  },
  {
      "text": "GOA",
      "value": "GOA"
  },
  {
      "text": "GUJARAT",
      "value": "GUJARAT"
  },
  {
      "text": "HARYANA",
      "value": "HARYANA"
  },
  {
      "text": "HIMACHAL PRADESH",
      "value": "HIMACHAL PRADESH"
  },
  {
      "text": "JAMMU AND KASHMIR",
      "value": "JAMMU AND KASHMIR"
  },
  {
      "text": "JHARKHAND",
      "value": "JHARKHAND"
  },
  {
      "text": "KARNATAKA",
      "value": "KARNATAKA"
  },
  {
      "text": "KERALA",
      "value": "KERALA"
  },
  {
      "text": "LADAKH",
      "value": "LADAKH"
  },
  {
      "text": "LAKSHADWEEP",
      "value": "LAKSHADWEEP"
  },
  {
      "text": "MADHYA PRADESH",
      "value": "MADHYA PRADESH"
  },
  {
      "text": "MAHARASHTRA",
      "value": "MAHARASHTRA"
  },
  {
      "text": "MANIPUR",
      "value": "MANIPUR"
  },
  {
      "text": "MEGHALAYA",
      "value": "MEGHALAYA"
  },
  {
      "text": "MIZORAM",
      "value": "MIZORAM"
  },
  {
      "text": "NAGALAND",
      "value": "NAGALAND"
  },
  {
      "text": "ODISHA",
      "value": "ODISHA"
  },
  {
      "text": "OTHER TERRITORY",
      "value": "OTHER TERRITORY"
  },
  {
      "text": "PUDUCHERRY",
      "value": "PUDUCHERRY"
  },
  {
      "text": "PUNJAB",
      "value": "PUNJAB"
  },
  {
      "text": "RAJASTHAN",
      "value": "RAJASTHAN"
  },
  {
      "text": "SIKKIM",
      "value": "SIKKIM"
  },
  {
      "text": "TAMIL NADU",
      "value": "TAMIL NADU"
  },
  {
      "text": "TELANGANA",
      "value": "TELANGANA"
  },
  {
      "text": "TRIPURA",
      "value": "TRIPURA"
  },
  {
      "text": "UTTAR PRADESH",
      "value": "UTTAR PRADESH"
  },
  {
      "text": "UTTARAKHAND",
      "value": "UTTARAKHAND"
  },
  {
      "text": "WEST BENGAL",
      "value": "WEST BENGAL"
  }
]

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});


Sentry.init({
  Vue,
  environment: process.env.VUE_APP_ENV,
  dsn: process.env.VUE_APP_Sentry,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
          "localhost",
          'app.qrapi.scanova.io',
          'app.api.staging.scanova.io',
          'app.qrapi.io'
        , /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
  
  // The timeout exception is probably caused due to recaptcha,
  // Reference : https://github.com/getsentry/sentry-javascript/issues/2514
  beforeSend(event,hint){
    if (hint.originalException === "Timeout") return null;
    return event;
  }
});
