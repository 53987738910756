import Vue from 'vue'
import VueRouter from 'vue-router'

import store from './store'
import Cookies from 'js-cookie';

// import WelcomePage from './components/welcome/welcome'
// import DashboardPage from './components/dashboard/dashboard'
// import SignUpPage from './components/auth/signup'
// import SignInPage from './components/auth/signin'
// import VerifyEmail from './components/verifyEmail/verifyemail'
// import Authenticate from './components/verifyEmail/authenticate'
// import Api from './components/dashboard/api'
// import Logs from './components/dashboard/logs'
// import Profile from './components/dashboard/profile'
// import Support from './components/dashboard/support'
// import Subscription from './components/dashboard/subsciption'
// import Notification from './components/dashboard/notification'
// import ResetPassword from './components/auth/reset-password'
// import ChangePassword from './components/auth/change-password'
// import Auth from './components/auth/auth'
// import ResendLink from './components/auth/resend-link'
// import Examples from './components/examples/example'

Vue.use(VueRouter);

let routes = [
  { path: '/', redirect: '/auth/login'},
  { path: '/examples',
    component: () => import(/* webpackChunkName: "example" */ './components/examples/example'), name: 'examples' },
  { path: '/auth', component: () => import(/* webpackChunkName: "auth" */ './components/auth/auth'),
    children: [{
      path: 'register',
      component: () => import(/* webpackChunkName: "signup" */ './components/auth/signup'),
      name: 'sign-up'
    },{
      path: 'login',
      component: () => import(/* webpackChunkName: "signin" */ './components/auth/signin'),
      name: 'login'
    }, {
      path: 'reset-password',
      component: () => import(/* webpackChunkName: "reset-password" */ './components/auth/reset-password'),
      name: 'reset-password'
    }, {
        path: 'resend-link',
        component: () => import(/* webpackChunkName: "reset-link" */ './components/auth/resend-link'),
        name: 'resend-link'
    }, {
      path: 'change-password',
      component: () => import(/* webpackChunkName: "change-password" */ './components/auth/change-password'),
      name: 'change-password'
    }, {
      path: 'verify-email',
      component: () => import(/* webpackChunkName: "verify-email" */ './components/verifyEmail/verifyemail'),
      name: 'verify-email'
    }, {
      path: 'authenticate',
      component: () => import(/* webpackChunkName: "authenticate" */ './components/verifyEmail/authenticate'),
      name: 'authenticate'
    }]
  }, { path: '/dashboard/',
    component: () => import(/* webpackChunkName: "dashboard" */ './components/dashboard/dashboard'),
    children: [{
      path:'api',
      component: () => import(/* webpackChunkName: "api" */ './components/dashboard/api'),
      name: 'dashboard-api'
    },{
      path:'logs',
      component: () => import(/* webpackChunkName: "logs" */ './components/dashboard/logs'),
      name: 'dashboard-logs'
    },{
      path:'media',
      component: () => import(/* webpackChunkName: "logs" */ './components/dashboard/media'),
      name: 'dashboard-media',
      beforeEnter:(to,from,next)=>{
        if(store.state.plan_type == 'Basic'){
          next({name: 'dashboard-api'})
        }else{
          next();
        }
      }
    },{
      path:'profile',
      component: () => import(/* webpackChunkName: "profile" */ './components/dashboard/profile'),
      name: 'dashboard-profile'
    },{
      path:'support',
      component: () => import(/* webpackChunkName: "support" */ './components/dashboard/support'),
      name: 'dashboard-support'
    },{
      path:'subscription',
      component: () => import(/* webpackChunkName: "subscription" */ './components/dashboard/subsciption'),
      name: 'dashboard-subscription'
    }, {
      path:'notifications',
      component: () => import(/* webpackChunkName: "notifications" */ './components/dashboard/notification'),
      name: 'notification'
    }, 
    ],
    beforeEnter (to, from, next) {
      // const accessToken = localStorage.getItem('token');
      // const expirationDate = localStorage.getItem('expirationDate');
      // const userId = localStorage.getItem('userId');
      const accessToken = Cookies.get('token');
      const expirationDate = Cookies.get('expirationDate');
      const userId = Cookies.get('userId');
      const plan_type = Cookies.get('plan_type');
      if(accessToken) {
        if (store.state.idToken) {
          next()
        } else {
          store.commit('setAuthTokenInfo', { accessToken, userId, expirationDate, plan_type });
          next()
        }
      } else {
        next({name: 'login'})
      }
    }
  }, { path: '*', component: () => import(/* webpackChunkName: "example" */ './components/dashboard/404Page.vue'), name: '404' }

];


if(process.env.VUE_APP_ENV == 'production_cre'){
  routes = [
    { path: '/', redirect: '/auth/login'},
    { path: '/examples',
      component: () => import(/* webpackChunkName: "example" */ './components/examples/example'), name: 'examples' },
    { path: '/auth', component: () => import(/* webpackChunkName: "auth" */ './components/auth/auth'),
      children: [{
        path: 'register',
        component: () => import(/* webpackChunkName: "signup" */ './components/auth/signup'),
        name: 'sign-up'
      },{
        path: 'login',
        component: () => import(/* webpackChunkName: "signin" */ './components/auth/signin_cre'),
        name: 'login'
      }, {
        path: 'reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ './components/auth/reset-password'),
        name: 'reset-password'
      }, {
          path: 'resend-link',
          component: () => import(/* webpackChunkName: "reset-link" */ './components/auth/resend-link'),
          name: 'resend-link'
      }, {
        path: 'change-password',
        component: () => import(/* webpackChunkName: "change-password" */ './components/auth/change-password'),
        name: 'change-password'
      }, {
        path: 'verify-email',
        component: () => import(/* webpackChunkName: "verify-email" */ './components/verifyEmail/verifyemail'),
        name: 'verify-email'
      }, {
        path: 'authenticate',
        component: () => import(/* webpackChunkName: "authenticate" */ './components/verifyEmail/authenticate'),
        name: 'authenticate'
      }]
    }, { path: '/dashboard/',
      component: () => import(/* webpackChunkName: "dashboard" */ './components/dashboard/dashboard'),
      children: [{
        path:'api',
        component: () => import(/* webpackChunkName: "api" */ './components/dashboard/api'),
        name: 'dashboard-api'
      },{
        path:'logs',
        component: () => import(/* webpackChunkName: "logs" */ './components/dashboard/logs'),
        name: 'dashboard-logs'
      },{
        path:'media',
        component: () => import(/* webpackChunkName: "logs" */ './components/dashboard/media'),
        name: 'dashboard-media',
      },{
        path:'profile',
        component: () => import(/* webpackChunkName: "profile" */ './components/dashboard/profile'),
        name: 'dashboard-profile'
      },{
        path:'support',
        component: () => import(/* webpackChunkName: "support" */ './components/dashboard/support'),
        name: 'dashboard-support'
      },{
        path:'subscription',
        component: () => import(/* webpackChunkName: "subscription" */ './components/dashboard/subsciption'),
        name: 'dashboard-subscription'
      }, {
        path:'notifications',
        component: () => import(/* webpackChunkName: "notifications" */ './components/dashboard/notification'),
        name: 'notification'
      }, 
      ],
      beforeEnter (to, from, next) {
        // const accessToken = localStorage.getItem('token');
        // const expirationDate = localStorage.getItem('expirationDate');
        // const userId = localStorage.getItem('userId');
        const accessToken = Cookies.get('token');
        const expirationDate = Cookies.get('expirationDate');
        const userId = Cookies.get('userId');
        const plan_type = Cookies.get('plan_type');
        if(accessToken) {
          if (store.state.idToken) {
            next()
          } else {
            store.commit('setAuthTokenInfo', { accessToken, userId, expirationDate, plan_type });
            next()
          }
        } else {
          next({name: 'login'})
        }
      }
    }, { path: '*', component: () => import(/* webpackChunkName: "example" */ './components/dashboard/404Page.vue'), name: '404' }
  
  ];
}

export default new VueRouter({mode: 'history', routes})
