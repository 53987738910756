<template>
  <b-overlay
    :show="loadingProcess"
    rounded="lg"
    variant="transparent"
    opacity="0.9"
    
  >
    <router-view></router-view>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <alert-box ref="alertBox"></alert-box>
    <userinfo-model ref="userinfoModel"></userinfo-model>
  </b-overlay>
</template>

<script>
export default {
  name: 'app',
  components: {
    ConfirmDialogue: () => import(/* webpackChunkName: 'ConfirmDialogue'*/ './components/widgets/confirmDialogue'),
    'alert-box': () => import(/*webpackChunkName: "Alert-Box"*/ './components/widgets/alertBox.vue'),
    UserinfoModel : () => import(/*webpackChunkName: "userinfoModel"*/ './components/widgets/userinfoModel.vue')
  },
  // computed: {
  //     auth () {
  //         return this.$store.getters.isAuthenticated
  //     }
  // }
  computed: {
    loadingProcess: {
      get() {
        return this.$store.state.loadingProcess;
      }
    }
  }
}
</script>

<style lang="scss">
  @import "components/scss/_variable.scss";
  body, html {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    height: 100% !important;
  }

  .page-info {
    background-color: #f8f8f8;
  }
  .popover, .popover-header {
    color: $theme-color!important;
    font-family: Montserrat,sans-serif!important;
    background-color: #FFFFFF!important;
  }

  .popover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .popover-header {
    border: none;
  }
  .popover:focus, .popover:active {
    border: none!important;
  }



  .w-15{
   width:15%
  }

  .bg-dark {
    background-color: $theme-color!important;
  }

  .custom-control-input:checked~.custom-control-label::before{
    border-color: $theme-color;
    background-color: $theme-color;
  }

  .progress-bar-color {
    background-color: $theme-color;
  }
  .progress {
    border-radius: 3.25rem;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .popover-icon {
    color: $popover-icon;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  .modal-content {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #ffffff;
  }
  .modal-title {
    font-weight: bold;
  }

  .modal-header {
    background-color: $popover-icon;
  }

  .modal-header .close {
    padding:0.2rem 1rem;
  }
  .close {
    opacity: 0.2;
    font-size: 3.5rem;
    font-weight: 500;
  }

  .modal-footer {
    border:none;
    font-weight: 600;
    padding: 1rem 2rem;

  }

  .modal-header button:focus{
    outline: none;
    box-shadow: none;
  }

  .btn-outline-warning {
    color: $theme-color;
    border-color: $theme-color;
    background-color: $background-color;
    box-shadow: 0 5px 7px 0 rgba(184, 181, 181, 0.5);
  }

  .btn-outline-warning:hover,
  .btn-outline-warning:active,
  .btn-outline-warning:focus,
  .btn-outline-warning:not(:disabled):not(.disabled):active{
    color: $background-color;
    border-color: $hover-color;
    background-color: $hover-color;
    box-shadow: none;
  }

  .btn-outline-secondary:hover,
  .btn-outline-secondary:active,
  .btn-outline-secondary:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled):active{
    color: $secondary-btn-clr;
    border-color: $secondary-btn-clr;
    background-color: $background-color;
    box-shadow: none;
  }



  /*.modal-dialog {*/
  /*  margin: 15rem auto;*/
  /*}*/

  .modal-footer .btn-secondary {
    background-color: $background-color;
    border-radius: $border-radius!important;
    border-color: $hover-color;
    color: $hover-color;
    width: 150px;
  }

  .modal-footer .btn-secondary:hover{
    background-color: $hover-color;
    color: $background-color;
  }

  .modal-footer .btn-primary {
    background-color: $theme-color;
    border-radius: $border-radius!important;
    border-color: $hover-color;
    color: $background-color;
    width: 150px;
  }

  .modal-footer .btn-primary:hover{
    background-color: $hover-color;
    color: $background-color;
  }

  .modal-footer .btn {
    //padding: 0.4rem 3rem;
  }

  .theme-bg {
    background-color: $theme-color;
  }

  .card-shadow {
    /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);*/
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  .text-color {
    color: #b9b9b9;
  }

  .text-color:hover {
    color: $theme-color;
    text-decoration: none;
  }

  .link-text {
    color: $theme-color;
  }

  .link-text:hover {
    color: $theme-color;
    text-decoration: underline;
  }


  .border-danger {
    border-color: $invalid-text!important;
  }

  .success-text {
    color: $success-color;
  }

  .error-text {
    color: $invalid-text;
  }

  .dropdown-item:active {
    color: $theme-color;
    text-decoration: none;
    background-color: transparent;
  }

  .input input {
    font: inherit;
    /*width: 91%;*/
    padding: 10px 15px;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    border-radius: $border-radius!important;
  }

  .input.inline input {
    width: auto;
  }

  .input input{
    background-color: $background-color;
  }
  .input .showPassword{
    position:relative;
    bottom: 40px;
    margin-right:12%;
  }

  .input input:focus {
    outline: none;
    border: 1px solid #585858;
    background-color: $background-color;
  }

  .input.invalid input {
    border: 1px solid $invalid-text;
    background-color: $background-color;
  }

  .input.invalid label {
    color: $invalid-text;
  }

  .submit button {
    color: $background-color;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
    background-color: $theme-color;
    box-shadow: 0 3px 4px 0 rgba(182, 173, 173, 0.5);
    border-radius: $border-radius!important;
  }

  .nav-pills .nav-link.active {
    background-color: $theme-color;
  }

  .nav-pills .nav-link {
    background-color: $tabs-In-active;
    color: #000000;
  }

  .submit button {
    outline: none;
  }

  .submit button:hover,
  .submit button:active {
    background-color: $hover-color;
    border: 1px solid $hover-color;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid $hover-color;
    background-color: transparent;
    color: $hover-color;
    cursor: not-allowed;
  }

  .theme-bg {
    background-color: $theme-color;
  }

  .white-round-cap {
    color: $theme-color;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
  }

  .white-round-cap-desktop {
    left:91%;
    top:38%;
  }

  .white-round-cap-mobile {
    left:45%;
    top:93%;
  }

  .cursor {
    cursor: pointer!important;
  }

  .submit button[disabled], .submit button[disabled]:hover {
    background-color: $theme-color;
    color: rgba(255, 255, 255, 0.5);;
  }
  .text-theme {
  color: $theme-color !important;
}

.authCard-h1 {
  font-size: 32px !important;
}

@media only screen and (max-width: 410px) {
  .modal-footer .btn {
    width: 100%;
  }
}
</style>
