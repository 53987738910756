import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios-auth'

import router from "./router";
import Cookies from 'js-cookie';


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    user: null,
    api: null,
    APIKey: null,
    authError: null,
    allImages: null,
    imageDetails: null,
    loadingProcess: false,
    plan_type : ''
  },
  mutations: {
    toggleLoadingProcess(state, val) {
      state.loadingProcess = val;
    },
    setActiveImageDetails(state, payload) {
      state.imageDetails = payload;
    },
    setAllImagesDetail(state, payload) {
      state.allImages = payload;
    },
    setAuthTokenInfo(state, data) {
      state.idToken = data.accessToken;
      state.userId = data.userId;
      state.plan_type = data.plan_type;
      // localStorage.setItem('token', data.accessToken);
      // localStorage.setItem('userId', data.userId);
      Cookies.set('token', data.accessToken);
      Cookies.set('userId', data.userId);
      Cookies.set('plan_type',data.plan_type);
      
    },
    authUser (state, userData) {
      state.idToken = userData.token;
      state.userId = userData.userId;
      state.plan_type = userData.plan_type;
    },
    storeUser (state, user) {
      state.user = user
    },
    clearAuthData (state) {
      state.idToken = null;
      state.userId = null;
      state.plan_type = '';
    },
    errorMessage (state, error) {
      state.authError = error
    },
    updatePlanType(state,val){
      state.plan_type = val;
      Cookies.set('plan_type',val);
    }
  },
  actions: {
    setLogoutTimer({ commit }, expirationTime) {
      setTimeout(() => {
        commit('clearAuthData')
      }, expirationTime * 1000)
    },
    signUp({ commit, dispatch }, authData) {
      return axios.post('/registerUser', authData)
    },
    authenticate({commit, dispatch}, authData) {
      return axios.get('/authenticate', { headers :
          { 'Authorization': `JWT ${authData.tokenId}`}})
    },
    setToken({commit, dispatch}, tokenData) {
      commit('authUser', {
          token:tokenData.token,
          userId:tokenData.userId,
          plan_type : tokenData.plan_type
      });
      const now = new Date();
      const expiryDate = new Date(now.getTime() + tokenData.expiry * 1000);
      // localStorage.setItem('token', tokenData.token);
      // localStorage.setItem('userId', tokenData.userId);
      // localStorage.setItem('expirationDate', expiryDate);
      Cookies.set('token', tokenData.token);
      Cookies.set('userId', tokenData.userId);
      Cookies.set('expirationDate', expiryDate);
      Cookies.set('plan_type',tokenData.plan_type);
      dispatch('setLogoutTimer', tokenData.expiry);
    },
    login({ commit, dispatch }, authData) {
      return axios.post('/loginUser', authData)
    },
    googleAuthSignUp({ state }, data) {
      return axios.post('/googleAuthSignUp', { data: data })
    },
    googleAuthLogin({ state }, data) {
      return axios.post('/googleAuthLogin', { data: data })
    },
    resetPassword({ commit, dispatch }, data) {
      return axios.post('/resetPassword', data)
    },
    resendLink({ commit, dispatch }, data) {
      return axios.post('/resendLink', data)
    },
    changePassword({ commit, dispatch }, data) {
      return axios.post('/changePassword', {
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword
      }, { headers :
          { 'Authorization': `JWT ${data.tokenId}`}})
    },
    supportEmail({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/supportEmail', {
        data: data
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    setValidationCode({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/setValidationCode', {
        task: data.task
      },
        { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    getAllAPIRequests({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.get('/getAllAPIRequests',
        { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    downloadInvoice({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/downloadInvoice', {
        orderId: data.order_id
      },
        { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    generateReport({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/generateReport', {
        formatType: data.formatType
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    verifyValidationCode({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/verifyValidationCode', {
        code: data.code,
        task: data.task
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    changeEmail({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/changeEmail', {
        email: data.email,
        task: data.task
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    deleteUserAccount({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/deleteAccount', { message: data.message} ,
        { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    changeUserPassword({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/changePassword', {
        currentPassword : data.currentPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
        loginType: data.loginType
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    sendEmail({ state }, data) {
      return axios.post('/sendEmail', { data: data })
    },
    verifyCode({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/verifyCode', {
        code: data.code,
        task: data.task,
        email: data.email
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    fetchAPI({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/getAPI', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    createCustomer({ state }, data) {
      if(!state.idToken) {
        return
      }
      // return axios.post('/createStripeCustomer', {...{
      //   name: data.name,
      //   address: data.address,
      //   city: data.city,
      //   state: data.state,
      //   zip: data.zip,
      //   country: data.country,
      //   tax_id: {
      //     type: data.tax_id.type,
      //     value: data.tax_id.value
      //   }
      //   }, stripe_country: data.stripe_country},
      let payload = {
        name: data.name,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country,
        stripe_country: data.stripe_country
      }
      if(data.hasOwnProperty('tax_id')) {
        payload.tax_id = {
          type: data.tax_id.type,
          value: data.tax_id.value
        }
      }
      return axios.post('/createStripeCustomer', payload,
        { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    getCustomerDetails({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/getCustomer', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    PaymentIntent({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/api/payments/createPaymentIntent', {
        paymentMethod: data.paymentMethod,
        plan_id: data.plan,
        save_card: data.saveCard,
        attachCustomer: data.attachCustomer,
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    updateNewCard({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/api/payments/update-payment-details', {
        payment_method_id: data.paymentMethod
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    fetchProrationPreview({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/api/payments/fetchProrationPreview', {
        plan_id: data.plan_id
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    fetchAllCards({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/api/payments/fetchAllCards', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    cancelRecurring({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/cancelRecurring', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    startRecurring({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/startRecurring', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    createAPIKey({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/createAPIKey', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    deleteAPIKey({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/deleteAPIKey', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    getAllPlans({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/getAllPlans', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    saveUserDetails({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/saveUserDetails', {
        data: data
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    profileBilling({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.put('/api/profile/billing', 
        {...data},
       { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    verifyPassword({ state }, data) {
      if(!state.idToken) {
        return
      }
      return axios.post('/verifyPassword', {
        password: data.password
      }, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    tryAutoLogin ({ commit }) {
      // const token = localStorage.getItem('token');
      const token = Cookies.get('token');
      if(!token) {
        return
      }
      // const expirationDate = localStorage.getItem('expirationDate');
      const expirationDate = Cookies.get('expirationDate')
      const now = new Date();
      if(now >= expirationDate) {
        return
      }
      // const userId = localStorage.getItem('userId');
      const userId = Cookies.get('userId');
      const plan_type = Cookies.get('plan_type');
      commit('authUser', {
        token: token,
        userId: userId,
        plan_type : plan_type
      });
      router.push({
        name: 'dashboard-api'
      });
    },
    logout({state, commit }) {
      axios.post('/api/users/logout', {
        token: state.idToken
      },
      { headers : { 'Authorization': `JWT ${state.idToken}`}})
      .then(()=>{})
      .catch(()=>{})
      .finally(()=>{
        commit('clearAuthData');
        // localStorage.removeItem('expirationDate');
        // localStorage.removeItem('token');
        // localStorage.removeItem('userId');
        Cookies.remove('expirationDate');
        Cookies.remove('token');
        Cookies.remove('userId');
        Cookies.remove('plan_type');
        router.push({ name: 'login'});
      });
    },
    getAllUploadedMedia({ state }) {
      if(!state.idToken) {
        return
      }
      return axios.get('/api/media/all-images', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    getImageDetails({ state }, payload) {
      if(!state.idToken) {
        return
      }
      return axios.get(`/api/media/image/${payload}`, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    deleteImage({ state }, payload) {
      if(!state.idToken) {
        return
      }
      return axios.delete(`/api/media/remove-image/${payload}`, { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    updateAltName({ state }, payload) {
      if(!state.idToken) {
        return
      }
      return axios.put(`/api/media/update-alt-name/${payload.id}`,
                        {alt_name: payload.alt_name}, 
                        { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    updateUserPlanType({commit},payload){
      commit('updatePlanType',payload.plan_type);
    },
    verifyGSTNumber({ state }, payload){
      if(!state.idToken){
        return
      }
      return axios.post('/api/gst/check-no',payload,{ headers : { 'Authorization': `JWT ${state.idToken}`}});
    },
    checkVerificationCode({},payload){
      return axios.post('/api/users/verify-code',payload);
    },
    resendVerificationCode({},payload){
      return axios.post('/api/users/resend-verification-code',payload);
    },
    login_cre({}, authData) {
      return axios.post('/api/cre/login', authData);
    },
    updateExpiry({state},payload){
      if(!state.idToken){
        return
      }
      return axios.post('/api/cre/update-expiry',payload,{ headers : { 'Authorization': `JWT ${state.idToken}`}});
    },
    getNewPasswordCre({state}){
      if(!state.idToken) {
        return
      }
      return axios.get('/api/cre/generate-password', { headers : { 'Authorization': `JWT ${state.idToken}`}})
    },
    saveNewPasswordCre({state},payload){
      if(!state.idToken){
        return 
      }
      return axios.post('/api/cre/save-password',payload, { headers : { 'Authorization': `JWT ${state.idToken}`}});
    },
    updateRequestCount({state},payload){
      if(!state.idToken){
        return 
      }
      return axios.post('/api/cre/update-request-count',payload, { headers : { 'Authorization': `JWT ${state.idToken}`}} )
    }
  },
  getters: {
    user (state) {
      return state.user
    },
    isAuthenticated (state) {
      return state.idToken !== null
    },
    authError (state) {
      return state.authError
    }
  }
})
